import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

const PageNotFoundPage = ({ data }) => (
  <Layout>
    <SEO
      title="Page not found"
      description={data.site.siteMetadata.description}
    ></SEO>
    <h1>🙈</h1>
    <h2>This page doesn't exist.</h2>
    Click <Link to="/">here</Link> to return to an existing page.
  </Layout>
)

export default PageNotFoundPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`
